/* .loading span {
  width: 0.75rem;
  height: 0.75rem;
  margin: 0 2px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  animation: jumpingDots infinite 1.5s linear;
}

.loading span:nth-child(2) {
  animation-delay: 0.4s;
}

.loading span:nth-child(3) {
  animation-delay: 0.8s;
}

@keyframes jumpingDots {
  20% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
} */

.loading-png {
  height: 40vmin;
  pointer-events: none;
  animation: launching infinite 1.4s linear;
}

@keyframes launching {
  20% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
}
